<template>
  <section :id="id"
    :class="`detect_deviece_${deviceType} state_${deviceType} page_is_${themeConfig.isArabia==1?'ar':'en'}`">
    <NavBar :title="title" @go-back="goback" />
    <slot />
    <FootModuleLoader :themeConfig="themeConfig" :host="host" :deviceType="deviceType" />
  </section>
</template>

<script>
import NavBar from '@/pages/components/NavBar.vue';
import FootModuleLoader from '@/pages/components/FootModuleLoader.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    NavBar,
    FootModuleLoader
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'deviceType',
      'themeConfig',
      'host',
    ])
  },
  methods: {
    handleSearch (data) {
      console.log(data);
      this.$router.push({ name: 'home', params: { restart: 'search' } }); //TODO:
    },
    handleMenuItem (data) {
      console.log(data);
      const processedData = data.split('.my')[0];
      console.log(processedData);
      window.location.href = `/${processedData}`;
    },
    goback () {
      this.$router.go(-1);
    }
  },
};
</script>